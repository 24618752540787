import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigatorService } from './services/navigator.service';
import { AppComponent } from './app.component';
import { TheHeaderComponent } from './components/the-header/the-header.component';
import { LoginComponent } from './components/login/login.component';
import { BenefitsViewComponent } from './components/view-benefits/benefits-view/benefits-view.component';
import { DialogBaseComponent } from './components/common/dialog-base/dialog-base.component';
import { InfoDialogComponent } from './components/common/info-dialog/info-dialog.component';
import { PasswordChangeComponent } from './components/password-change/password-change.component';
import { WaitingSpinnerComponent } from './components/common/waiting-spinner/waiting-spinner.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { PasswordRequirementsDialogComponent } from './components/common/password-requirements-dialog/password-requirements-dialog.component';
import { BenefitPresenterComponent } from './components/view-benefits/benefit-presenter/benefit-presenter.component';
import { CartViewComponent } from './components/view-cart/cart-view/cart-view.component';
import { CartItemPresenterComponent } from './components/view-cart/cart-item-presenter/cart-item-presenter.component';
import { YesNoDialogComponent } from './components/common/yes-no-dialog/yes-no-dialog.component';
import { InitialDataComponent } from './components/initial-data/initial-data.component';
import { ViewContactsComponent } from './components/view-contacts/view-contacts.component';
import { TheFooterComponent } from './components/the-footer/the-footer.component';
import { ViewLandingPageComponent } from './components/view-landing-page/view-landing-page.component';
import { ViewBenefitDetailsComponent } from './components/view-benefit-details/view-benefit-details.component';
import { AdministrationViewComponent } from './components/view-administration/administration-view/administration-view.component';
import { ReportsViewComponent } from './components/view-administration/reports-view/reports-view.component';
import { ReportPresenterComponent } from './components/view-administration/report-presenter/report-presenter.component';
import { EmailsViewComponent } from './components/view-administration/emails-view/emails-view.component';
import { EmailPresenterComponent } from './components/view-administration/email-presenter/email-presenter.component';
import { ScoringViewComponent } from './components/view-administration/scoring-view/scoring-view.component';
import { UsersManagementViewComponent } from './components/view-administration/users-management-view/users-management-view.component';
import { DatabaseManagementComponent } from './components/view-administration/database-management-view/database-management-view.component';
import { DatabaseManagementPresenterComponent } from './components/view-administration/database-management-presenter/database-management-presenter.component';
import { PromoCampaignManagementComponent } from './components/view-administration/promo-campaign-management-view/promo-campaign-management-view.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { ItemsListComponent } from './components/items-list/items-list.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { TruncatePipe } from './utils/truncate.pipe';
import { UserEditViewComponent } from './components/view-administration/users-management-view/user-edit-view/user-edit-view.component';
import { UserViewComponent } from './components/view-user/user-view/user-view.component';
import { UserDetailsViewComponent } from './components/view-user/user-details-view/user-details-view.component';
import { AlertComponent } from './components/common/alert/alert.component';
import { AlertService } from './components/common/alert/alert.service';
import { ViewRewardsComponent } from './components/view-administration/view-rewards/view-rewards.component';
import { PasswordInfoDialogComponent } from './components/common/password-info-dialog/password-info-dialog.component';

const routes: Routes = [
  { 
    path: NavigatorService.loginScreenPath,
    component: LoginComponent
  },
  {
    path: NavigatorService.landingPagePath,
    component: ViewLandingPageComponent
  },
  {
    path: NavigatorService.initialDataScreenPath,
    component: InitialDataComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: NavigatorService.passwordChangeScreenPath,
    component: PasswordChangeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: NavigatorService.passwordResetScreenPath,
    component: ResetPasswordComponent,
    canActivate: [AuthGuard],
  },
  { 
    path: NavigatorService.benefitsScreenPath,
    component: BenefitsViewComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: NavigatorService.benefitDetailsScreenPath,
    component: ViewBenefitDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: NavigatorService.adminReportsScreenPath,
    component: ReportsViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: NavigatorService.cartScreenPath,
    component: CartViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: NavigatorService.contactsScreenPath,
    component: ViewContactsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: NavigatorService.administrationScreenPath,
    component: AdministrationViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: NavigatorService.usersManagementcreenPath,
    component: UsersManagementViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: NavigatorService.scoringScreenPath,
    component: ScoringViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: NavigatorService.prizesScreenPath,
    component: ViewRewardsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: NavigatorService.databaseManagementScreenPath,
    component: DatabaseManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: NavigatorService.emailMessagesScreenPath,
    component: EmailsViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: NavigatorService.promoCampaignManagementPath,
    component: PromoCampaignManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: NavigatorService.userEditScreenPath,
    component: UserEditViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: NavigatorService.userPath,
    component: UserViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: NavigatorService.userDetailsPath,
    component: UserDetailsViewComponent,
    canActivate: [AuthGuard]
  },
    
  ];
  
  @NgModule({
    declarations: [
    AppComponent,
    TheHeaderComponent,
    LoginComponent,
    BenefitsViewComponent,
    DialogBaseComponent,
    InfoDialogComponent,
    PasswordChangeComponent,
    WaitingSpinnerComponent,
    ResetPasswordComponent,
    PasswordRequirementsDialogComponent,
    ReportsViewComponent,
    BenefitPresenterComponent,
    CartViewComponent,
    CartItemPresenterComponent,
    YesNoDialogComponent,
    ReportPresenterComponent,
    InitialDataComponent,
    ViewContactsComponent,
    TheFooterComponent,
    ViewLandingPageComponent,
    ViewBenefitDetailsComponent,
    AdministrationViewComponent,
    UsersManagementViewComponent,
    ScoringViewComponent,
    EmailsViewComponent,
    EmailPresenterComponent,
    DatabaseManagementComponent,
    DatabaseManagementPresenterComponent,
    PromoCampaignManagementComponent,
    ItemsListComponent,
    PaginatorComponent,
    TruncatePipe,
    UserEditViewComponent,
    UserViewComponent,
    UserDetailsViewComponent,
    AlertComponent,
    ViewRewardsComponent,
    PasswordInfoDialogComponent
  ],
  imports: [
    MatDialogModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatButtonModule
  ],
  exports: [
    RouterModule,
    AlertComponent
  ],
  providers: [
    AlertService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
