<div class="login-form-container">
  <form [formGroup]="loginForm" (ngSubmit)="confirm()">
    <div class="login-form-items-container">
      <div class="login-form-title-container">
        <span>Logowanie</span>
      </div>
      <div class="login-form-input-description-container">
        <span>Adres email z Panasonic PRO Club</span>
        <input type="email" class="form-control" formControlName="email"/>
        <span *ngIf="formSubmitted && loginControls.email.errors"
          class="input-error">Adres email jest wymagany</span>
      </div>
      <div class="login-form-input-description-container">
        <span>Hasło</span>
        <input type="password" class="form-control" formControlName="password"/>
        <span *ngIf="formSubmitted && loginControls.password.errors"
          class="input-error">Hasło jest wymagane</span>
      </div>
      <div class="login-dorm-error-info-container"
        *ngIf="invalidAuthenticationData">
        <span>Niepoprawne dane logowania</span>
      </div>
      <div class="login-form-submit-button-container">
        <button class="btn btn-primary btn-primary-custom">Zaloguj</button>
      </div>
      <div class="login-button-container">
        <button type="button" class="btn-textOnly" (click)="showPasswordOrigins()">Jak uzyskać hasło?</button>
        <button type="button" class="btn-textOnly" (click)="resetPassword()">Nie pamiętam hasła</button>
      </div>
    </div>
  </form>
</div>
<waiting-spinner *ngIf="isSpinnerVisible" [text]="'Uwierzytelnianie...'"></waiting-spinner>
