import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'password-info-dialog',
  templateUrl: './password-info-dialog.component.html',
  styleUrl: './password-info-dialog.component.css'
})
export class PasswordInfoDialogComponent {
  public isErrorInfo: boolean = false;
  public okFunc: (() => void) | null = null;

  constructor(private dialogReference: MatDialogRef<PasswordInfoDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public config: any) {
    this.isErrorInfo = config?.isErrorInfo ?? false;
    this.okFunc = config?.okFunc ?? null;
  }

  public confirmMessage() : void {
    if (this.okFunc) {
      this.okFunc();
    }
    this.dialogReference.close();
  }
}
